<template>
    <div class="main">
        <div class="program-item" v-for="item in list" :key="item.id" @click="goDetail(item)">
            <img :src="constant.URL + item.videoCover" alt="">
            <p>{{item.contestProjectName}}</p>
        </div>
    </div>
</template>
<script>
export default {
    name:'program',
    data(){
        return {
            list:[],
            areaId:'',
        }
    },
    watch:{
        "$store.state.spotrsindex"(newval){
            if(newval){
                this.areaId = this.$store.state.spotrsindex;
                this.init();
            }
        }
    },
    mounted(){
        this.areaId = this.$store.state.spotrsindex;
        this.init();
    },
    methods:{
        init(){
            this.api.sports.projectList(this.areaId).then((res)=>{
                if(res.flag){
                    this.list = res.data;
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        goDetail(data){
            // this.$router.push({
            //     path:'/sportsClassVideo',
            //     query:{
            //         normalUrl:data.videoId,
            //         contestProjectName:data.contestProjectName,
            //         teachAccessories:data.teachAccessories
            //     }
            // })
        }
    }
}
</script>
<style scoped lang="scss">
.main{
    margin:0 auto;
    width:1394px;
    padding-left:40px;
    padding-bottom:60px;
    background:#FFFFFF;
    @include flex(row, flex-start,flex-start);
    flex-wrap: wrap;
    .program-item{
        margin-right:36px;
        margin-top:30px;
        width: 310px;
        height: 202px;
        background: #FFFFFF;
        box-shadow: 0px 2px 18px 0px rgba(75, 75, 75, 0.4);
        border-radius: 6px;
        position: relative;
        // cursor:pointer;
        >img{
            width:100%;
            height:100%;
        }
        > p{
            position: absolute;
            bottom: 0px;
            left: 0;
            text-align:center;
            width:100%;
            height: 54px;
            line-height:54px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            background: #BB3037;
            border-radius: 0px 0px 6px 6px;
        }
    }
}

</style>